import React from 'react';
import {Alert} from 'reactstrap'
import BG from '../../assets/Rectangle.png';
import CARD_BG from '../../assets/CardBG.png';
import POLYGON from '../../assets/Polygon.png';
import STAR2 from '../../assets/RepeatGrid2.png';
import STAR3 from '../../assets/RepeatGrid3.png';
import STAR5 from '../../assets/RepeatGrid5.png';
import Rupees from '../../assets/Rupees.png';
import BACK from '../../assets/RectangleBack.png';
import SUBS from '../../assets/SUBSCRIPTION.png';
import { Row,Col,Container,Button,Card} from 'react-bootstrap';
import {makeStyles} from '@material-ui/core';
import PaymentGateWay from './PaymentGateWay2';
import {Input} from '@material-ui/core';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {  CardHeader, CardFooter, CardBody,
	CardTitle, CardText } from 'reactstrap';
const tawkTo = require("tawkto-react");
const tawkToPropertyId = '5f5b18d5f0e7167d000f56d2'
// export default class Pricing extends React.PureComponent{
	

const useStyles = makeStyles({
	card:{
		'& span':{
			display:'none'
		},
		'&:hover':{
			    '& div':{
					width:'100%',
				},
				'& span':{
					color:'white',
					display:'block',
					fontSize:'1.1rem',
					fontWeight:'800'
				},
				'& card-body':{
					width:'100%'
				},
				color:'yellow',
				'& ul':{
				
				},
				'& ul li':{
					color:'black'
				}
		}
	}
})

export default function Pricing(){
	
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:600px)");
	
	// React.useEffect(() => {
	// 	tawkTo(tawkToPropertyId)
	// }, [])
	const [coupon,setCoupon] = React.useState('');
	
	return(     
	  <Container   fluid style={{backgroundImage:`url(${BG})`,width:matches?'80vw':'100vw',display:'flex',justifyContent:'center',alignItems:'center',marginBottom:'10rem'}} >
	    
		<img   src={SUBS} style={{width:'25%',position:'absolute',top:'85px',backgroundColor:'#f89b1c',borderRadius:'30px',padding:'0.7rem',right:'-15px', marginTop:'50px'}} />
		                         
                 
		<Row  style={{marginTop:'8rem',width:'100%'}} >
				<Card>
					<CardHeader><h5 style={{textAlign:'center'}} > &emsp;  First Time Offer for limited period.Use coupon code <span class="badge badge-warning">FIRST50</span></h5></CardHeader>
					<CardBody>
					{/* <CardTitle tag="h5">Special Title Treatment</CardTitle> */}
					<CardText> 
					<p style={{textAlign:'center'}} >&emsp;    At MentoMeet, we provide the best services from top mentors to quality tests at a very reasonable price.
					100s of students have put their trust in MentoMeet because we believe in&emsp;quality over quantity.
					We provide a wide range of subscription plans by keeping in mind the needs of our Students.
					Try our services once Contact us for any Query regarding payment&emsp;at<a href="mailto:contact@mentomeet.com" style={{color:"#3F5E26"}} target="_blank" className="col-12">contact@mentomeet.com</a>+91 6378963002</p></CardText>					</CardBody>
					<CardFooter><h5 style={{textAlign:'center'}} >Steps to pay using PhonePe/Gpay- 1.Click 'BUY NOW' 2.Choose 'UPI QR' 3.Click 'PAY' 4.Scan QR from your App </h5></CardFooter>
					{/* <CardFooter><h5 style={{textAlign:'center'}} >&emsp;   You can also contact us, if you need any <span class="badge badge-warning" style={{whiteSpace:'break-spaces'}}  >&emsp;   Financial support in our prices upto 30-50% Discount</span></h5></CardFooter> */}
      			</Card>

			<Col className={classes.card}  style={{marginTop:'2rem',width:'90%'}} >
				<Card style={{maxWidth:'300px'}} >
				  <Card.Header style={{backgroundColor:'#f89b1c',height:'auto',borderBottomRightRadius:'40px'}} >
				  <span>FIRST50 </span>
				    <span>TRY NOW LIMITED OFFER</span>
					<h3>DIAMOND- 1 YEAR</h3>
					<p><img src={STAR2} style={{width:'50px'}} /></p>
					<p style={{display:'flex',marginRight:'0' ,textDecoration: 'line-through',width:'20px',height:'25px',marginRight:'10px',marginLeft:'auto' }} >9999</p>
					<h2 style={{display:'flex',marginRight:'0'}} ><img src={Rupees} style={{width:'20px',height:'25px',marginRight:'10px',marginLeft:'auto'}} />8999</h2>				  
					</Card.Header>
				  <Card.Body style={{width:'100%'}} >
                          <ul>
							<li>	Unlimited chat access with your mentor</li>
							<li>	Weekly Video Call Help from Mentor </li>
							<li>	Study material recommendation and Website summary notes</li>
							<li>	Unlimited Mock test and Answer Key Analysis</li>
							<li>	Academic Doubts,Time management and Evaluation</li>
							<li>	Constant Motivation,guidance,Webinars and personalized progress report</li>
						  </ul>
					</Card.Body>
				   <Card.Footer style={{width:'100%'}} >
				        <img src={POLYGON} style={{position:'absolute',bottom:'10px',right:'30px',width:'30px'}} />			
						<Input
						  type="text"
						  id='coupon-code'
						  name='coupon'
						  placeholder='Enter Coupon For Availing Discount'
						   style={{width:'100%',marginBottom:'1rem'}}
						  vaue={coupon}
						 onChange={e => setCoupon(e.target.value)}
					
						/>
					   	<PaymentGateWay coupon={coupon} amount={899900} PlanId="601308b9af3c7e63a7b658f7" />			 
				   </Card.Footer>
                 </Card>	
			</Col>

			<Col  className={classes.card} style={{marginTop:'2rem',width:'90%'}} >	
				<Card style={{maxWidth:'300px'}} >
				  <Card.Header style={{backgroundColor:'#f89b1c',height:'auto',borderBottomRightRadius:'40px'}} >
				  
				  <span>FIRST50 </span>
				    <span>TRY NOW LIMITED OFFER</span>
					<h3>PLATINUM- 6 MONTHS</h3> 
					<p><img src={STAR2} style={{width:'50px'}} /></p>
					<p style={{display:'flex',marginRight:'0' ,textDecoration: 'line-through',width:'20px',height:'25px',marginRight:'10px',marginLeft:'auto' }} >7999</p>
					<h2 style={{display:'flex',marginRight:'0'}} ><img src={Rupees} style={{width:'20px',height:'25px',marginRight:'10px',marginLeft:'auto'}} />5299</h2>				  
				  </Card.Header>
				  <Card.Body style={{width:'100%'}} >
				  		<ul>
							<li>	Unlimited chat access with your mentor</li>
							<li>	Weekly Video Call Help from Mentor </li>
							<li>	Study material recommendation and Website summary notes</li>
							<li>	Unlimited Mock test and Answer Key Analysis</li>
							<li>	Academic Doubts,Time management and Evaluation</li>
							<li>	Constant Motivation,guidance,Webinars and personalized progress report</li>
						</ul>
					</Card.Body>
				   <Card.Footer style={{width:'100%'}} >
				        <img src={POLYGON} style={{position:'absolute',bottom:'10px',right:'30px',width:'30px'}} />			
						<Input
						  type="text"
						  id='coupon-code'
						  name='coupon'
						  placeholder='Enter Coupon For Availing Discount'
						   style={{width:'100%',marginBottom:'1rem'}}
						  vaue={coupon}
						 onChange={e => setCoupon(e.target.value)}	
						/>
				
					   	<PaymentGateWay coupon={coupon}  amount={529900} PlanId="601308b9af3c7e63a7b658f6"/>			 
				   </Card.Footer>
                 </Card>	
			</Col>
			
			<Col  className={classes.card}  style={{marginTop:'2rem',width:'90%'}}>
				<Card style={{maxWidth:'300px'}} >
				
				  <Card.Header style={{backgroundColor:'#f89b1c',height:'auto',borderBottomRightRadius:'40px'}} >
				    <span>FIRST50 </span>
				    <span>TRY NOW LIMITED OFFER</span>
					<h3>GOLD- 3 MONTHS</h3>
					<p><img src={STAR2} style={{width:'50px'}} /></p>
					
				 	<p style={{display:'flex',marginRight:'0' ,textDecoration: 'line-through',width:'20px',height:'25px',marginRight:'10px',marginLeft:'auto' }} >5449</p>
					<h2 style={{display:'flex',marginRight:'0'}} ><img src={Rupees} style={{width:'20px',height:'25px',marginRight:'10px',marginLeft:'auto'}} />3299</h2>				  			
					</Card.Header>
				  <Card.Body style={{width:'100%'}}>
				  		<ul>
							<li>	Limited chat access with your mentor</li>
							<li>	Weekly Video Call Help from Mentor </li>
							<li>	Study material recommendation and limited Website summary notes</li>
							<li>	Unlimited Mock test and Answer Key Analysis</li>
							<li>	Limited Academic Doubts,Time management and Evaluation</li>
							<li>	Constant Motivation,guidance,Webinars and personalized progress report</li>
						</ul>
					</Card.Body>
				   <Card.Footer style={{width:'100%'}} >
				        <img src={POLYGON} style={{position:'absolute',bottom:'10px',right:'30px',width:'30px'}} />			
						<Input
						  type="text"
						  id='coupon-code'
						  name='coupon'
						  placeholder='Enter Coupon For Availing Discount'
						   style={{width:'100%',marginBottom:'1rem'}}
						  vaue={coupon}
						 onChange={e => setCoupon(e.target.value)}
	
						/>
						{/* Original - 601308b9af3c7e63a7b658f9  Testing ---  600ffd2bfc3d33149c925e43   */}
					   	<PaymentGateWay coupon={coupon} amount={329900} PlanId="601308b9af3c7e63a7b658f9" />			 
          
				   </Card.Footer>
                 </Card>
			</Col>

			<Col  className={classes.card} className={classes.card} style={{marginTop:'2rem',width:'90%'}} >
				<Card style={{maxWidth:'300px'}} >
				  <Card.Header style={{backgroundColor:'#f89b1c',height:'auto',borderBottomRightRadius:'40px'}} >
					<span>FIRST50 </span>
				    <span>TRY NOW LIMITED OFFER</span>
					<h3>SILVER- 1 MONTH</h3>
					<p><img src={STAR2} style={{width:'50px'}} /></p>
					<p style={{display:'flex',marginRight:'0' ,textDecoration: 'line-through',width:'20px',height:'25px',marginRight:'10px',marginLeft:'auto' }} >1999</p>
					<h2 style={{display:'flex',marginRight:'0'}} ><img src={Rupees} style={{width:'20px',height:'25px',marginRight:'10px',marginLeft:'auto'}} />1299</h2>
				</Card.Header>
				  <Card.Body style={{width:'100%'}} >
				 		<ul>
							<li>	Limited chat access with your mentor</li>
							<li>	Weekly Video Call Help from Mentor </li>
							<li>	Study material recommendation and Website summary notes</li>
							<li>	Limited Mock test and Answer Key Analysis,Q&A section</li>
							<li>	Academic Doubts,Time management and Evaluation</li>
							<li>	Constant Motivation,guidance,Webinars and personalized progress report</li>
						</ul>                        
					</Card.Body>
				   <Card.Footer style={{width:'100%'}} >
				        <img src={POLYGON} style={{position:'absolute',bottom:'10px',right:'30px',width:'30px'}} />			
						<Input
						  type="text"
						  id='coupon-code'
						  name='coupon'
						  placeholder='Enter Coupon For Availing Discount'
						   style={{width:'100%',marginBottom:'1rem'}}
						  value={coupon}
						 onChange={e => setCoupon(e.target.value)}
						  	
						/>
				       
					   	<PaymentGateWay coupon={coupon} amount={129900} PlanId="601b0d137de4551cf96bdb1f" />			 
          
				   </Card.Footer>
                 </Card>
				
			</Col>
			
			<Col  className={classes.card} style={{marginTop:'2rem',width:'90%'}} >
				<Card style={{maxWidth:'300px',minHeight:'670px'}} >
				  <Card.Header style={{backgroundColor:'#f89b1c',height:'auto',borderBottomRightRadius:'40px'}} >
				  	<span>FIRST50 </span>
				    <span>TRY NOW LIMITED OFFER</span>
					<h3>BASIC-MENTO CALL</h3>	
					<p><img src={STAR2} style={{width:'50px'}} /></p>	
					<span style={{display:'flex' ,textDecoration: 'line-through',width:'20px',height:'20px',marginRight:'30px',marginLeft:'auto',marginBottom:'10px' }} ><b>449</b></span>
					<h2 style={{display:'flex',marginRight:'0',alignItems:'center'}} ><img src={Rupees} style={{width:'20px',height:'25px',marginRight:'20px',marginLeft:'auto'}} /><b>249</b></h2>	
		                     </Card.Header>
				  <Card.Body style={{width:'100%'}} >
                          <ul>
								<li>1 Audio/Video Call With IIT/NEET Mentor</li>
								<li>Study material recommendation and Website summary notes</li>
								<li>Limited Mock test and Answer Key Analysis,Q&A section</li>
								<li>Academic Doubts,Guidance,Time management and Evaluation</li>
						  </ul>
					</Card.Body>
				   <Card.Footer style={{width:'100%'}} >
				        <img src={POLYGON} style={{position:'absolute',bottom:'10px',right:'30px',width:'30px'}} />			
						<Input
						  type="text"
						  id='coupon-code'
						  name='coupon'
						  placeholder='Enter Coupon For Availing Discount'
						   style={{width:'100%',marginBottom:'1rem'}}
						  vaue={coupon}
						 onChange={e => setCoupon(e.target.value)}
						 		
						/>
					   	<PaymentGateWay coupon={coupon}  amount={24900} PlanId="601308b9af3c7e63a7b658fc"/>			 
          
				   </Card.Footer>
                 </Card>		
			</Col>
		</Row>
	  </Container>	
	)
}
