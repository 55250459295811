import React from 'react';
import { useHistory } from 'react-router-dom';


function QualitySection() {

  const history = useHistory();
  return (
    <section className="Quality">
      <h2>Why You Should Be a Mentor on <span className="mento-meet">MentoMEET</span></h2>
      <div className="Quality-options">
        <div className="option">
          <h3>Start Your Journey to Influence</h3>
          <h5>
            Always wanted to share your skills? MentoMEET lets you turn your
            professional knowledge into a rewarding side hustle.
          </h5>
        </div>
        <div className="option">
          <h3>Connect with Ambitious Learners</h3>
          <h5>
            From personalized 1:1 mentorship to group workshops, reach
            motivated individuals and help shape their success story.
          </h5>
        </div>
        <div className="option">
          <h3>Expand Your Network While Earning</h3>
          <h5>
            Build a thriving community around your expertise, exchange
            knowledge, and get paid for helping others grow.
          </h5>
        </div>
        <div className="option">
          <h3>Resume Enhancement</h3>
          <h5>
          Boost Your Profile: Elevate your resume by showcasing your mentorship experience, demonstrating your dedication to community engagement and leadership.
          </h5>
        </div>
      </div>
      <div className='option-btn'>
      <button
          onClick={() => {
            history.push("/register");
          }}
          // className="mentor-btn"
        >
          Join as a Mentor
        </button>
        </div>
    </section>
  );
}

export default QualitySection;
