import React from "react";
import "./App.css";
import { Button } from "../../Button";
import { PersonalizedImg } from "../../PersonalizedImg";
import { ReactComponent as MentorShipSvg } from "./Mentorship.svg";
import { ReactComponent as SkillSvg } from "./skill.svg";
import PlacementPng from "./placementprep.png";
// import { ReactComponent as PlacementPrepSvg} from './placementprep.svg';


const App = () => {
  return (
    
    <div>
       <offer id="offer" className="offer"></offer>
      <div className="care-container">
        <h2>
          Guiding <span style={{ color: "#5A159E" }}> Students </span> at
          <span style={{ color: "#5A159E" }}> Every Stage </span> of Their
          Educational Journey
        </h2>
        <p>
          From exam preparation to career development, we help students succeed
          at every milestone
        </p>

        <div className="offer-container">
          <h2>
            What we{" "}
            <span style={{ color: "#5a159e", fontFamily: "800" }}>offer?</span>
          </h2>

          <div class="grid-container">
            <div class="grid-item">
              <h3>Career Discovery</h3>
              <div className="info-container">
               
                  <p>
                    Identify your strengths and explore career options with our
                    personalized assessments.
                  </p>
                  {/* <button>Learn more</button> */}
               

                <PersonalizedImg  style={{ width: "150px", height: "150px" }} />
              </div>
            </div>
            <div class="grid-item">
              <h3>Personalized Mentorship</h3>
              <div className="info-container">
                
                  <p>
                    Find your mentor for personalized guidance that keeps you on
                    track to achieve success.
                  </p>
                  {/* <button>Learn more</button> */}
                
                <MentorShipSvg style={{ width: "200px", height: "150px" }} />
              </div>
            </div>
            <div class="grid-item">
              <h3>Skill Development</h3>
              <div className="info-container">
              
                  <p>
                    Build practical, hands-on skills that prepare you for
                    real-world challenges.
                  </p>
                  {/* <button>Learn more</button> */}
                
                <SkillSvg style={{ width: "150px", height: "140px" }} />
              </div>
            </div>
            <div class="grid-item">
              <h3>Placement Preparation</h3>
              <div className="info-container">
               
                  <p>
                    Prepare for job placements with resume reviews, mock
                    interviews, and industry insights.
                  </p>
                  {/* <button>Learn more</button> */}
                
                <img src={PlacementPng} style={{ width: "150px", height: "150px", background:"transparent" }}alt="placement" />
                {/* <PlacementPrepSvg style={{ width: "150px", height: "150px" }}/> */}
                {/* <PlacementSvg style={{ width: "150px", height: "150px" }} /> */}
              </div>
            </div>
          </div>
            {/* <div className="container-btn"> 
              <button>Explore Our Services</button>
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default App;
