import React from "react";
import "../WhyMentomeet.css";
import Expert from "../../../assets/homepage/Expert.svg";
import Solve from "../../../assets/homepage/Solve.svg";
import Call from "../../../assets/homepage/LiveCall.svg";
import Path from "../../../assets/homepage/Path6.svg";
import YouTube from "react-youtube";

export default function WhyMentomeet() {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 0,
    },
  };

  

  return (
    <div className="discover-container">
      <p className="title">Discover how <span className="mento-meet">MentoMEET</span> Helps You Succeed</p>

      <div className="discover-body">
        <ul className="custom-bullets">
          <li>
         <span style={{ color: "#ff9500", fontFamily: "900" }}>Personalized Motivation and Guidance:</span> Stay inspired with expert tips from top mentors, helping you maintain focus and motivation on your academic journey.
          </li>
          <li>
          <span style={{ color: "#ff9500", fontFamily: "900" }}>Custom Daily Routine Plans:</span> Receive tailored advice on how to create effective daily schedules, boosting your productivity and ensuring steady progress.
          </li>
          <li>
          <span style={{ color: "#ff9500", fontFamily: "900" }}> 1:1 Personalized Mentorship:</span> Benefit from one-on-one guidance that addresses your unique needs, whether you're preparing for exams or exploring career options.
          </li>
          <li>
          <span style={{ color: "#ff9500", fontFamily: "900" }}> Efficient Paper Solving & Time Management:</span> Master critical exam techniques, including practice paper solving and effective time management, guided by experienced mentors.
          </li>
          <li>
          <span style={{ color: "#ff9500", fontFamily: "900" }}>Comprehensive Academic and Career Growth:</span> Experience a holistic approach that balances academic excellence with practical skill development, preparing you for professional success.
          </li>
          {/* <li>
          Practical Skill Development (upcoming)
          Build essential, real-world skills in coding, communication, and beyond through hands-on projects.
          </li>
          <li>
          Placement Support for a Strong Start  (upcoming)
          Get job-ready with resume help, mock interviews, and personalized career advice.
          </li> */}
        </ul>
        <YouTube videoId="7_ZJ_PJD1V0" opts={opts} />;
      </div>
    </div>
  );
}
