import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Badge } from "reactstrap";
import { Redirect } from "react-router-dom";
import MeetForm from "./MeetForm";

const MentorDashboard = () => {
  const [Questions, setQuestions] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [approved, setApproved] = useState(false);
  const [clicked, SetClicked] = useState(false);
  const [question, SetQuestion] = useState("");
  const color = {
    JEE: "warning",
    NEET: "info",
    CAREER: "success",
  };
  console.log(Questions);
  const QuestionGetter = (path) => {
    //console.log(`https://${window.location.hostname}:5005/mentor/${path}`)
    let userID = JSON.parse(localStorage.getItem("user"))._id;
    Axios.get(
      `https://${window.location.hostname}:5005/mentor/${path}/${userID}`
    )
      .then((res) => {
        setQuestions(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("user"))) return;
    let category = JSON.parse(localStorage.getItem("user")).category;
    QuestionGetter(`allquestions/${category}`);
    // SetRole((user.category))
    console.log(category);
  }, []);

  if (JSON.parse(localStorage.getItem("user")) == undefined) {
    return <Redirect to="/" />;
  }

  const handleApprove = (q) => {
    SetQuestion(q);
    SetClicked(!clicked);
  };

  const gotoApprove = () => {
    setApproved(!approved);
    setQuestions([]);
    let category = JSON.parse(localStorage.getItem("user")).category;
    if (!approved) QuestionGetter("approvedqns");
    else QuestionGetter(`allquestions/${category}`);
  };
  return (
    <div className="min-vh-100">
      <Container fluid>
        <ToastContainer position="bottom-left" />
        <Row
          style={{
            marginTop: "1rem",
            marginBottom: "2rem",
            margin: "0 auto",
            maxWidth: "80vw",
          }}
        >
          <Col md={4}>
            <h3 style={{ marginTop: "10px" }}>
              {!approved ? <>New Requests</> : <>Approved</>}
            </h3>
          </Col>
          <Col md={{ span: 4, offset: 4 }}>
            <Button style={{ marginTop: "1rem" }} onClick={gotoApprove}>
              {approved ? <> Back</> : <>My History</>}
            </Button>
          </Col>
        </Row>
        <MeetForm
          clicked={clicked}
          SetClicked={SetClicked}
          user={user}
          question={question}
          setQuestions={setQuestions}
        />
        <Row style={{ margin: "2rem auto 2rem auto", maxWidth: "70vw" }}>
          {Questions.map((question, index) => (
            <Col>
              <Card style={{ width: "400px", marginTop: 10 }} key={index}>
                <Card.Header style={{ backgroundColor: "rgb(255, 239, 0)" }}>
                  <Card.Title>
                    <Badge color={color[question.category]}>
                      {question.category}
                    </Badge>
                    {question.headline}
                  </Card.Title>
                </Card.Header>
                <Card.Body style={{ padding: "10px", textAlign: "center" }}>
                  <Card.Text>
                    <Card.Text>
                      <b>Mentee Name </b>:{" "}
                      <span className="text-info">
                        {question.menteeName.toUpperCase()}
                      </span>{" "}
                    </Card.Text>
                    {question.requestTo ? (
                      <Card.Text>
                        <b>Request For Mentor </b>:{" "}
                        <span className="text-info">
                          {question.requestTo.toUpperCase()}
                        </span>{" "}
                      </Card.Text>
                    ) : null}
                    <Card.Text
                      style={{
                        marginTop: "5px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <b>Application Date </b>{" "}
                      <span style={{ color: "red", textAlign: "center" }}>
                        {new Date(question.applicationDate).toDateString()}
                      </span>{" "}
                    </Card.Text>
                    <Card.Text
                      style={{
                        marginTop: "5px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <b>Selected Date </b>{" "}
                      <span style={{ color: "red", textAlign: "center" }}>
                        {new Date(question.selectedDate).toDateString()}
                      </span>{" "}
                    </Card.Text>
                    {question.slot ? (
                      <Card.Text>
                        <b>Selected Time Slot </b>:{" "}
                        <span className="text-info">
                          {question.slot.toUpperCase()}
                        </span>{" "}
                      </Card.Text>
                    ) : null}
                    <Card.Text style={{ marginTop: "5px" }}>
                      <div className="hideDetails">
                        <p className="card-title queryTitle text-secondary ">
                          <span className="text-dark">
                            <b>My Query</b>
                          </span>{" "}
                          : {question.query.toUpperCase()}
                        </p>
                      </div>
                      <Button
                        variant="info"
                        style={{ border: "none" }}
                        onClick={(e) => {
                          console.log(
                            e.target.parentNode.firstChild.classList.toggle(
                              "hideDetails"
                            )
                          );
                        }}
                      >
                        More
                      </Button>
                    </Card.Text>
                    <Card.Text style={{ marginTop: "10px" }}>
                      {approved ? (
                        <>
                          <p>
                            <b>Approved on </b>:{" "}
                            <span style={{ color: "green" }}>
                              {new Date(question.selectedDate).toDateString()}
                            </span>
                          </p>
                          <br />
                          {question.selectedTime ? (
                            <p>
                              <b>Meeting Time </b> :{" "}
                              <span style={{ color: "green" }}>
                                {new Date(question.selectedTime).getHours() > 12
                                  ? new Date(question.selectedTime).getHours() -
                                    12
                                  : new Date(question.selectedTime).getHours()}
                                :
                                {new Date(question.selectedTime)
                                  .getMinutes()
                                  .toString()
                                  .padStart(2, 0)}
                                <span>
                                  {new Date(question.selectedTime).getHours() >=
                                  12
                                    ? "PM"
                                    : "AM"}
                                </span>
                              </span>
                            </p>
                          ) : null}

                          <Button
                            color="info"
                            style={{ fontSize: 14 }}
                            href={question.MeetLink}
                            onClick={(e) =>
                              alert(
                                `This Link will Redirect you to: ${question.MeetLink}`
                              )
                            }
                          >
                            Meet Link
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            variant="info"
                            onClick={() => {
                              handleApprove(question);
                            }}
                          >
                            Approve
                          </Button>
                        </>
                      )}
                    </Card.Text>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};
export default MentorDashboard;
