import React from "react";
import { Link } from "react-router-dom";

import brand from "../assets/brand.png";
import MenuIcon from "@material-ui/icons/Menu";
import Dialog from "@material-ui/core/Dialog";
import {
  Slide,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openFAQ: false,
      showMobileLinks: false,
    };
  }

  handleClose = () => {
    this.setState({ openFAQ: false });
  };

  handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    window.location.href = "/index";
  };

  handleopenFAQ = () => {
    this.setState({ openFAQ: true });
  };

  getMockTestDropDownContent = () => {
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;

    if (user?.role == "Mentor") {
      return (
        <div className="dropdown-menu">
          <Link
            className="dropdown-item"
            to={user ? "/mentor/createtest" : "/login"}
          >
            Create Test
          </Link>
          <Link
            className="dropdown-item"
            to={user ? "/mentor/history/testhistory" : "/login"}
          >
            My Mock Tests
          </Link>
        </div>
      );
    }

    return (
      <div className="dropdown-menu">
        <Link
          to={user ? "/mentee/mocktest" : "/login"}
          className="dropdown-item"
        >
          All Tests
        </Link>
        <Link
          to={user ? "/mentee/mocktest/history" : "/login"}
          className="dropdown-item"
        >
          History
        </Link>
      </div>
    );
  };

  getRoutes = (user) => {
    return (
      <div
        // className="collapse navbar-collapse"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav">
          <li className="nav-item mx-3">
            <Link className="nav-link  " to="/about-us">
              About us
            </Link>
          </li>
          {/* Resource Corner */}
          <li className="nav-item mx-3">
            <a href="/index#offer" className="nav-link">
              Services
            </a>
          </li>

          {/* Conditional Rendering for Mentor and Mentee */}
          {user?.role.toLowerCase() === "mentee" ? (
            <>
              <li className="nav-item dropdown mx-3">
                <a
                  className="nav-link dropdown-toggle mr-3"
                  href="#"
                  id="plansDropdown"
                  role="button"
                  data-toggle="dropdown"
                >
                  Plans
                </a>
                <div className="dropdown-menu">
                  <Link className="dropdown-item text-info" to="/pricing">
                    JEE/NEET
                  </Link>
                  <Link className="dropdown-item text-info" to="/plans">
                    Engineering College
                  </Link>
                </div>
              </li>
            </>
          ) : null}
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Resources
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <Link to="/qna" className="dropdown-item">
                QnA
              </Link>
              <Link to="/blogs" className="dropdown-item">
                Blogs
              </Link>
              <div className="dropdown-submenu">
                <Link
                  to={user ? "/mentee/mocktest" : "/login"}
                  className="dropdown-item dropdown-toggle"
                >
                  Mock Test
                </Link>
                {this.getMockTestDropDownContent()}
              </div>
              <Link
                to={user ? "/studyMaterials" : "/login"}
                className="dropdown-item"
              >
                Study Material
              </Link>
              <Link to="/webinar" className="dropdown-item">
                Event
              </Link>
            </div>
          </li>
          <li className="nav-item mx-3">
            <a href="#footer" className="nav-link">
              Contact us
            </a>
          </li>
        </ul>
      </div>
    );
  };

  render() {
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;

    return (
      <>
        {/* FAQ Dialog */}
        <Dialog
          open={this.state.openFAQ}
          TransitionComponent={Transition}
          onClose={this.handleClose}
          aria-labelledby="FAQs"
          fullWidth={true}
          maxWidth={"lg"}
        >
          <DialogTitle>Frequently Asked Questions:</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {/* FAQs content here */}
              {/* For brevity, I’ve omitted the FAQ content */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Navbar */}
        <div className="navBarContainer">
          <div className="mentomeetLogo">
            <a href="/index" className="d-flex justify-content-center align-items-center" >
              <img src={brand} width={30} height={30} alt="Brand" />
              <h5
                className="mentomeetLogoText  d-none d-lg-block"
                style={{
                  color: "#1a1a1a",
                  fontSize: "20px",
                  display: "inline",
                  fontFamily: "poppins",
                  fontWeight: "bold",
                  paddingLeft: 8,
                  textDecoration: "none",
                }}
              >
                MentoMEET
              </h5>
            </a>
            <nav className="navbar navbar-toggler-container shadow navbar-expand-lg sticky-top">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#mobileNavigation"
              >
                <MenuIcon />
              </button>
            </nav>
          </div>

          {this.getRoutes(user)}
          <div className="d-flex align-items-start justify-content-end">
            {user?.role != "Mentor" ? (
              <button
                className="MentorBtn d-none d-md-block"
                onClick={() => {
                  this.props.history.push("/mentor");
                }}
              >
                Become a Mentor
              </button>
            ) : null}
            <button
              className="MentorBtn d-block d-md-none"
              onClick={() => {
                this.props.history.push("/mentor");
              }}
            >
              Be a Mentor
            </button>
            {/* Logout or Login */}
            {!user ? (
              <>
                <Link className="signupBtn" to="/register">
                  Signup
                </Link>
                <Link className="loginBtn" to="/login">
                  Login
                </Link>
              </>
            ) : null}

            {user ? (
              <>
                <div className="nav-item different font-weight-bold dropdown">
                  <a
                    className="nav-link text-info"
                    href="#"
                    id="dropdownMenuButton"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {user?.firstName + " " + user?.lastName}
                  </a>

                  <div className="dropdown-menu">
                    <Link className="dropdown-item text-info" to="/profile">
                      Profile
                    </Link>
                    <Link
                      className="dropdown-item  text-info"
                      to={`/${user.role.toLowerCase()}/dashboard`}
                    >
                      Dashboard
                    </Link>
                    <Link
                      className="dropdown-item  text-info"
                      to="/"
                      onClick={this.handleLogout}
                    >
                      Logout
                    </Link>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div id="mobileNavigation" className="collapse navbar-collapse">
          {this.getRoutes(user)}
        </div>
      </>
    );
  }
}

export default withRouter(NavBar);
