import React from "react";
// import "./Whatyoucando.css"; // Import the CSS file

function Whatyoucando() {
  return (
    <div className="provide-container">
     <h2>What You Can Do as a <span className="mento-meet">MentoMEET</span> Mentor</h2>
      <div className="item-container">
        <div className="key-item">
          <img
            src="https://topmate.io/_next/image?url=%2Fimages%2Fcategory-page%2Fimg-category-why-2.svg&w=256&q=75&dpl=dpl_9wXRGZKevCqJLhMXQyztqV6QEJTM"
            alt="Career Sessions"
          />
          <h3>Host 1:1 Career Sessions</h3>
          <p>
            Provide personalized guidance, from interview prep to skill
            development, and give mentees the tools they need to succeed.
          </p>
        </div>
        <div className="key-item">
          <img
            src="https://topmate.io/_next/image?url=%2Fimages%2Fcategory-page%2Fimg-category-why-3.svg&w=256&q=75&dpl=dpl_9wXRGZKevCqJLhMXQyztqV6QEJTM"
            alt="Workshops or Webinars"
          />
          <h3>Lead Workshops or Webinars</h3>
          <p>
            Share your knowledge with larger groups through webinars and
            interactive sessions to create real impact.
          </p>
        </div>
        <div className="key-item">
          <img
            src="https://topmate.io/_next/image?url=%2Fimages%2Fcategory-page%2Fimg-category-why-1.svg&w=256&q=75&dpl=dpl_9wXRGZKevCqJLhMXQyztqV6QEJTM"
            alt="Career Insights"
          />
          <h3>Share Your Short Notes</h3>
          <p>
          Offer concise, high-quality notes to simplify complex topics.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Whatyoucando;
