import React from "react";
// import './Collaboration.css';
import "./Collaboration.css";
// import { ReactComponent as Handshake } from "./handshake.svg";
import { Link } from 'react-router-dom';

const NewCollaboration = () => {

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <section className="collaboration">
      <div className="collaboration-options">
        {/* <div className="option">
          <h3>Passionate About Mentorship?</h3>
          <h5>
            Join Our Team! If you’re passionate about helping students succeed,
            apply as a mentor today and make a positive impact.
          </h5>
          <button>Join as a Mentor</button>
          <Handshake style={{ margin: "40px" }} />
        </div> */}
        <div className="option">
          {/* <h3>Partner With Us!</h3> */}
          <h3>
          Would You Like to <span style={{ color: "#FF9500" }}>Work</span> with <span style={{ color: "#FF9500" }}>Us?</span> 
         </h3>
          <h5>
          We welcome schools, nonprofits, and job seekers to collaborate with us. Together, we can offer students the support they need to succeed academically and professionally.
          </h5>
          {/* <button>Partner with Us</button> */}
          {/* <Handshake style={{ margin: "40px" }} /> */}
        </div>
      </div>
      <h2>
        Connect with us to explore{" "}
        <span style={{ color: "#FF9500" }}>collaboration</span> opportunities.
      </h2>
      <button className="btn-primary" onClick={() => openInNewTab('https://forms.gle/GngDp7UNu2HsNRJK6')}>
        Connect Us
      </button>
     
    
     
    </section>
  );
};

export default NewCollaboration;
