import React from "react";
import './Media.css';
import { ReactComponent as MediaImg} from './MediaImg.svg';
// import JagranImg from './JagranImg.png';
// import express from './express.png';
// import feleisure from './feleisure.png';
// import etnowImg from './etnowImg.png'
// import entrackerImg from './entrackerImg.png'


// import Launch from '../assets/news/launch.jpg';
// import Srijan from '../assets/news/srijan.png';
import Dainik from '../assets/news/dainik.png';
import Srijannit from '../assets/news/srijannit.png';
import Ministry from '../assets/news/ministry.png';
import Istart from '../assets/news/istart.png';


const NewMedia = () => {
  return (
    <section className="media">
      <h2>Recognized <span style={{color:'#5a159e'}}>By</span></h2>
      {/* <h5>We've Made it to the News Section.</h5> */}
      <div className="media-cards">
        <div className="media-card">
        <img src={Dainik} alt="dainik" style={{ width: "200px", height: "200px" , background:"#f0f4f8" }} />
        </div>
        <div className="media-card">
        <img src={Srijannit} alt="srijannit" style={{ width: "200px", height: "200px", background:"#f0f4f8" }} />
        </div>
        <div className="media-card">
        <img src={Ministry} alt="Ministry" style={{ width: "200px", height: "200px", background:"#f0f4f8" }} />
        </div>
        <div className="media-card">
        <img src={Istart} alt="istart" style={{ width: "200px", height: "200px", background:"#f0f4f8" }} />
        </div>
        {/* <div className="media-card">
        <img src={JagranImg} alt="Jagran" style={{ width: "200px", height: "200px" }} />
        </div> */}
        {/* <div className="media-card">
        <img src={express} alt="express" style={{ width: "200px", height: "200px" }} />
        </div> */}
        {/* <div className="media-card">
        <img src={feleisure} alt="feleisure" style={{ width: "200px", height: "200px" }} />
        </div> */}
        {/* <div className="media-card">
        <img src={etnowImg} alt="etnow" style={{ width: "200px", height: "200px" }} />
        </div> */}
        {/* <div className="media-card">
        <img src={entrackerImg} alt="entracker" style={{ width: "200px", height: "200px" }} />
        </div> */}
      </div>
    </section>
  );
};

export default NewMedia;

 
