import React from "react";
import Mentors from "../Mentors";
import "./MentorList.css";
import AbdulImg from '../../../assets/members/abdul.jpg';

// import abhik from "../../../assets/members/abhik.jpeg";
// import rathod from "../../../assets/members/rathod.jpeg";
import hardik from "../../../assets/members/hardik.jpg";
// import saurabh from "../../../assets/members/saurabh.jpg";
// import nitesh from "../../../assets/members/nitesh.jpg";
import priyanshi from "../../../assets/members/priyanshi.jpg";
import ankit from "../../../assets/members/ankit.jpeg";
import rudra from "../../../assets/members/rudra.jpg";
// import rohit from "../../../assets/members/rohitmalawat.jpeg";

const mentors = [
  { name: "Rudraksh Vyas", category: "NEET", title: "JLN AJMER" , imgUrl: rudra},
  { name: "Hardik Dhayal", category: "NEET", title: "AIIMS Delhi" , imgUrl: hardik},
  { name: "Ankit Kumar", category: "NEET", title: "IIT Roorkee" , imgUrl: ankit},
//   { name: "Shivani", category: "NEET", title: "Expert",imgUrl: rudra },
//   { name: "Harshita", category: "JEE", title: "Mentor", imgUrl: rudra },
  { name: "Priyanshi Gupta", category: "JEE", title: "IIT Roorkee" ,imgUrl: priyanshi},
  { name: "Abdul", category: "PLACEMENT", title: "JP Morgan" , imgUrl: AbdulImg},
];

function MentorLists() {
  return (
    <>
      <Mentors />
      <div className="top-mentors-container">
        <div className="top-mentors-body">
          <h2 className="title">
            Top Mentors Making an{" "}
            <span style={{ color: "#FF9500" }}>Impact</span>
          </h2>
          <p className="sub-title">
            Meet our <span style={{ color: "#FF9500" }}>Dedicated Mentors</span>,
            who are experts in their fields, committed to helping students achieve their goals.
          </p>
          <div className="mentor-card-container">
            {mentors.map((mentor) => (
              <div key={mentor.name}>
                <a
                  href={`/profile/${mentor.name.replace(/\s+/g, '-').toLowerCase()}`}
                  className="mentor-card-link"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <div className="mentor-card">
                    <div className="orange"></div>
                    <img src={mentor.imgUrl} alt={mentor.name} />
                    <p className="mentor-name">{mentor.name}</p>
                    <p className="mentor-role">{mentor.title}</p>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}


export default MentorLists;
