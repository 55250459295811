import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Control, LocalForm, Errors } from "react-redux-form";
import {
  Row,
  Label,
  Col,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
} from "reactstrap";
import axios from "axios";
import brand from "../../assets/brand.png";
import otp from "./Otp";

const required = (val) => val && val.length;
const validEmail = (val) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

class Login extends Component {
  constructor(props) {
    super(props);

    const urlObj = new URL(window.location.href);
    const searchParams = urlObj.searchParams;
    const isMentor = searchParams.get("user") === "mentor";
    console.log("init",isMentor)
    this.state = {
      register: props.isRegistering,
      role: isMentor ? 'Mentor':'Mentee'
    };
  }

  onChangeHandler = (e) => {
    console.log("this.ste",this.state)
    this.setState({
      register: !this.state.register,
    });
  };

  handleRegister = (values) => {
    var loginData = {
      email: values.email,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      gender: values.gender,
      role: values.role,
      category: values.category,
      mobile: values.mobile,
      heardFrom: values.heardFrom,
    };
    /*  axios.post(`https://${window.location.hostname}:5005/checkemail`, loginData)
        .then(response => {
        
            console.log("Response is - ")
            console.log(response)
            
                if(response.status=== 200 ){
                   
                    axios.post(`https://${window.location.hostname}:5005/email`, values)
                    .then(response => {
                
                        console.log("Response is - ")
                        console.log(response)
                        this.setState({register:false})
                
                    }).catch(function(err){      
                        console.log("catch err is ");
                        console.log(err)  
                        alert("Alert of Error!")
                    });
                                 
                   this.props.history.push(`/Otp?q=${JSON.stringify(values)}`); 
                }
                else{
                    alert("email is already registered");
                }
        
        }).catch(function(err){      
            alert("email already registered")
        }); */
    ////////////////////
    //new implementation
    axios
      .post(`https://${window.location.hostname}:5005/signUp`, loginData)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("email", loginData.email);
          this.props.history.push(`/Otp`);
        } else {
          alert("Email already registered");
        }
      })
      .catch(function (err) {
        alert("Server Error");
      });
  };
  handleLogin = (values) => {
    // alert(JSON.stringify(values));
    axios
      .post(`https://${window.location.hostname}:5005/signIn`, values)
      .then((response) => {
        if (response.data.msg === "NOT_VERIFIED") {
          alert("wait");

          localStorage.setItem("email", values.email);
          this.props.history.push(`/Otp`);
        } else if (response.status !== 401 && response.status !== 400) {
          if (response.data.user.email) {
            // console.log("Token is " + response.data.token)
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            // alert("Successfully logged in!")
            if (localStorage.getItem("redirect")) {
              window.location.href = localStorage.getItem("redirect");
              localStorage.removeItem("redirect");
            } else {
              window.location.href = "/";
            }
          }
        }
      })
      .catch(function (err) {
        console.log("catch err is ");
        console.log(err.error);
        alert("Invalid Credentials.Please try Again!");
      });
  };

 

  componentDidUpdate(prevProps) {
    // Re-render if the route changes
    console.log("this.props", this);
    const urlObj = new URL(window.location.href);
    const searchParams = urlObj.searchParams;
    const isMentor = searchParams.get("user") === "mentor";
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.props.isRegistering) {
        this.setState({
          role: isMentor ? "Mentor" : "Mentee",
          register: true,
        });
      } else {
        this.setState({
          role: isMentor ? "Mentor" : "Mentee",
          register: false,
        });
      }
      // Handle the route change
      this.forceUpdate(); // Optionally, trigger re-render
    }
  }

  render() {
    let LoginDiv = (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card-group shadow">
              <div className="card border-warning text-white">
                <div className="card-body m-3">
                  <LocalForm onSubmit={(values) => this.handleLogin(values)}>
                    <h3 className="text-warning mb-0">MentoMEET</h3>
                    <p className="text-muted font-weight-bold">
                      Login in your account
                    </p>
                    <InputGroup className="mb-3 d-block">
                      <Control.text
                        model=".email"
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        className="form-control w-100"
                        validators={{
                          required,
                        }}
                      />
                      <Errors
                        className="text-danger"
                        show="touched"
                        model=".email"
                        messages={{
                          required: "This is a Required Field!",
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="mb-3 d-block">
                      <Control.password
                        model=".password"
                        id="password"
                        name="password"
                        placeholder="password"
                        className="form-control w-100"
                        validators={{
                          required,
                        }}
                      />
                      <Errors
                        className="text-danger"
                        show="touched"
                        model=".password"
                        messages={{
                          required: "This is a Required Field!",
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <Button
                        type="submit"
                        block
                        color="info"
                        style={{ margin: "0" }}
                      >
                        Login
                      </Button>
                    </InputGroup>

                    <div className="">
                      <h6 className="text-muted mb-0">Don't have account??</h6>
                      <a
                        className="text-info font-weight-bold cursor-pointer mb-0"
                        onClick={this.onChangeHandler}
                      >
                        Register.
                      </a>
                      <br></br>
                      <Link
                        className="text-info font-weight-bold cursor-pointer"
                        to="/forgotpassword"
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </LocalForm>
                </div>
              </div>
              <div className="card d-none d-lg-block bg-info">
                <div className="card-body text-white m-3">
                  <h3>MentoMeet</h3>
                  <p>
                    Meet your true mentors through MentoMeet. We introduce you
                    to the world of true mentorship and building blocks for a
                    strong foundations to your bright future. Let us come
                    together to creat a great teaching and learning experience
                    for both students and mentors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    let RegisterDiv = (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-7">
            <div className="card-group shadow">
              <div className="card border-warning text-white">
                <div className="card-body m-3">
                  <LocalForm onSubmit={(values) => this.handleRegister(values)}>
                    <h3 className="text-warning">MentoMeet</h3>
                    <p className="text-muted font-weight-bold">
                      Welcome to new world
                    </p>
                    <div className="d-flex mb-3 w-100">
                      <InputGroup className="mr-1 d-block">
                        <Control.text
                          model=".firstName"
                          id="firstName"
                          name="firstName"
                          placeholder="First Name"
                          className="form-control w-100"
                          validators={{
                            required,
                          }}
                        />
                        <Errors
                          className="text-danger"
                          show="touched"
                          model=".firstName"
                          messages={{
                            required: "Required! ",
                          }}
                        />
                      </InputGroup>
                      <InputGroup className="ml-1 d-block">
                        <Control.text
                          model=".lastName"
                          id="lastName"
                          name="lastName"
                          placeholder="Last Name"
                          className="form-control w-100"
                          validators={{
                            required,
                          }}
                        />
                        <Errors
                          className="text-danger"
                          show="touched"
                          model=".lastName"
                          messages={{
                            required: "Required! ",
                          }}
                        />
                      </InputGroup>
                    </div>
                    <div className="d-flex w-100 mb-3">
                      <InputGroup className="mr-2 d-block">
                        <Control.select
                          model=".gender"
                          id="gender"
                          name="gender"
                          defaultValue="Male"
                          className="custom-select w-100"
                          validators={{
                            required,
                          }}
                        >
                          {/* <option value="-1">gender..</option> */}
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Others">Others</option>
                        </Control.select>
                        <Errors
                          className="text-danger"
                          show="touched"
                          model=".gender"
                          messages={{
                            required: "Required! ",
                          }}
                        />
                      </InputGroup>
                      <InputGroup clasName="d-block">
                        <Control.select
                          model=".role"
                          id="role"
                          name="role"
                          defaultValue={this.state.role}
                          className="custom-select w-100"
                          validators={{
                            required,
                          }}
                        >
                          {/* <option value="-1">Categories..</option> */}
                          <option value="Mentor">Mentor</option>
                          <option value="Mentee">Mentee</option>
                        </Control.select>
                        <Errors
                          className="text-danger"
                          show="touched"
                          model=".role"
                          messages={{
                            required: "Required! ",
                          }}
                        />
                      </InputGroup>
                    </div>
                    <InputGroup clasName="mb-3 d-block">
                      <Control.select
                        model=".category"
                        id="category"
                        name="category"
                        defaultValue="JEE"
                        className="custom-select w-100"
                        validators={{
                          required,
                        }}
                      >
                        {/* <option value="-1">Categories..</option> */}
                        <option value="JEE">JEE</option>
                        <option value="NEET">NEET</option>
                        <option value="CAREER">College</option>
                      </Control.select>
                      <Errors
                        className="text-danger"
                        show="touched"
                        model=".category"
                        messages={{
                          required: "Required! ",
                        }}
                      />
                    </InputGroup>

                    <InputGroup className="mb-3 mt-3 d-block">
                      <Control.select
                        model=".heardFrom"
                        id="heardFrom"
                        name="heardFrom"
                        defaultValue="NONE"
                        className="custom-select w-100"
                      >
                        {/* <option value="-1">Categories..</option> */}
                        <option value="NONE">
                          From Where You Heard About Us
                        </option>
                        <option value="LINKEDIN">LINKEDIN</option>
                        <option value="YOUTUBE">YOUTUBE</option>
                        <option value="INSTAGRAM">INSTAGRAM</option>
                        <option value="TELEGRAM">TELEGRAM</option>
                        <option value="QUORA">QUORA</option>
                        <option value="WHATSAPP">WHATSAPP</option>
                        <option value="OTHER">OTHER SOURCE</option>
                      </Control.select>
                    </InputGroup>
                    <InputGroup className="mb-3 mt-3 d-block">
                      <Control.input
                        model=".mobile"
                        type="number"
                        id="mobile"
                        name="mobile"
                        placeholder="Mobile"
                        className="form-control w-100"
                        validators={{
                          required,
                          validNumber: (value) => value && value.length === 10,
                        }}
                      />
                      <Errors
                        className="text-danger"
                        show="touched"
                        model=".mobile"
                        messages={{
                          required: "Required! ",
                          validNumber: "Mobile number is not correct",
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="mb-3 d-block">
                      <Control.text
                        model=".email"
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        className="form-control w-100"
                        validators={{
                          required,
                          validEmail,
                        }}
                      />
                      <Errors
                        className="text-danger"
                        show="touched"
                        model=".email"
                        messages={{
                          required: "Required! ",
                          validEmail: " Invalid Email!",
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="mb-3 d-block">
                      <Control.password
                        model=".password"
                        id="password"
                        name="password"
                        placeholder="Password"
                        className="form-control w-100"
                        validators={{
                          required: (val) => val && val.length > 7,
                        }}
                      />
                      <Errors
                        className="text-danger"
                        show="touched"
                        model=".password"
                        messages={{
                          required: "password is too short! ",
                          length: "password is too short",
                        }}
                      />
                    </InputGroup>

                    <InputGroup className="mb-3">
                      <Button
                        type="submit"
                        block
                        color="info"
                        style={{ margin: "0" }}
                      >
                        Register
                      </Button>
                    </InputGroup>

                    <div className="text-center">
                      <h6 className="text-muted mb-0">
                        Already have an account?{" "}
                        <a
                          className="text-info font-weight-bold cursor-pointer"
                          onClick={this.onChangeHandler}
                        >
                          Login.
                        </a>
                      </h6>
                    </div>
                    <hr />
                    <div className="text-center text-muted">
                      <p className="mb-0">
                        By registering you agree to our Terms & conditions and
                        Privacy Policies
                      </p>
                    </div>
                  </LocalForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div>
        {/* <nav className="navbar shadow  navbar-expand sticky-top navbar-light bg-light">
                    <a className="navbar-brand text-warning" href="/index"><img src={brand} alt="Brand" width="120"/></a>
                    
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item mx-3 font-weight-bold different">
                                <a className="nav-link text-info cursor-pointer login" onClick={this.onChangeHandler}>{this.state.register?"Register":"Login"}</a>
                            </li>
                        </ul>
                    </div>
                </nav> */}
        <div
          className="d-flex align-items-center "
          style={{ height: "auto", margin: "50px 10px" }}
        >
          {this.state.register ? RegisterDiv : LoginDiv}
        </div>
      </div>
    );
  }
}

export default Login;
