import React from "react";

function Works() {
  const steps = [
    {
      heading: "Sign Up",
      text: "Create your mentor profile by sharing your expertise, qualifications, and areas of interest.",
    },
    {
      heading: "Get Verified",
      text: " Our team will review and approve your profile to ensure quality and credibility.",
    },
    {
      heading: "Connect with Students",
      text: "Students will find and reach out to you for mentorship on topics you specialize in.",
    },
    {
      heading: "Mentor and Earn",
      text: "Provide personalized guidance, solve doubts, and mentor students while earning for each session.",
    },
  ];

  return (
    <div className="how-we-work-container">
      <h2>How Does<span className="mento-meet">MentoMEET</span> Work for Mentors?</h2>
      <div className="how-we-work-grid">
        {steps.map((step, index) => (
          <div className="work-card" key={index}>
            <p className="heading">{step.heading}</p>
            <p>{step.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Works;
