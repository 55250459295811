import React, { useState, useEffect } from "react";

import "./mentor.css";
import QualitySection from "./whymentor.js";
import StatsSection from "./stats.js";
import MentorVideo from "./video.js";
import YouTube from "react-youtube";
import Works from "./works.js";
import Whatyoucando from "./provide.js";
import { useHistory } from 'react-router-dom';

const MentorHomePage = () => {
  const history = useHistory();
  return (
    <div className="hero-section hero-mentor-section">
      <h2>
        Turn Your <span className="highlight">Expertise</span> into <span style={{color:'#ff9500'}}>Impact</span>
      </h2>
      <p>
        Guide, inspire, and connect with learners while earning on your terms.
      </p>
      <div className="main-action-buttons">
        <button
          onClick={() => {
            history.push("/register?user=mentor");
          }}
          className="mentor-btn"
        >
          Become a Mentor
        </button>
      </div>
      <img className="coding" src="https://mymaarg.com/img/coding.svg" />
      <div className="supporting-text">
        <p>
          Light the way for students Always wanted to share your skills ?
          MentoMEET lets you turn your professional knowledge into a rewarding
          side hustle.
        </p>
      </div>
      <QualitySection />
      <Whatyoucando />
      <StatsSection />
      <Works />
      <MentorVideo />
    </div>
  );
};

export default MentorHomePage;
